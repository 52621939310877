import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'
import { isWeixin, getNetworkType } from './platform'
import { v1 } from 'uuid'
import { UAParser } from 'ua-parser-js'
import qs from 'qs'

/** 
 * 公共参数 
 */
interface HttpCommonParams {
  /** 唯一ID：看看客户端是取自哪个地方，或者自己生成 */
  did: string
  /** 渠道编码：ios：ios Android：配置在渠道包里的值 微信：wx */
  cc: string
  /** 操作系统： 1 ios，2 android，3pc */
  os: number
  /** 操作系统版本 */
  osv: string, // 
  /** 软件版本 */
  ver: string, // 
  /** 浏览器 */
  bro: string, // 
  /** 浏览器版本号 */
  brov: string, // 
  /** 设备id android:imei ios:idfa pc:同did */
  deviceId: string, // 
  /** 纬度：取不到填0 */
  lat: number,	// 维度：取不到填0
  /** 经度：取不到填0 */
  lon: number, // 
  /** 运营商：1移动、2联通、3电信、4热点、0未知 */
  net: number, // 
  /** 第几代：2:2G、3:3G、4:4G、0：未知 */
  na: number, // 
  /** 0app；1微信；2微博；3QQ；4PC；5M */
  platform: number, // 
  /** 请求唯一ID：uuid生成 */
  requestId: string,	// 
  /** API 版本号，为日期形式：YYYYMMDD 当前版本：20181025 */
  v: string,	// 
  /** 签名结果串，关于签名的计算方法，请参见签名机制。（鉴于要更换为https，此处签名可以不用传） */
  s?: string, // 
  /** 请求的时间戳。毫秒 */
  t: number, // 
}

/**
 * Http错误信息
 */
interface HttpError {
  msg: string
}

/**
 * http请求配置参数
 */
interface HttpRequestConfig extends AxiosRequestConfig {
  /** 
   * 额外的配置信息，附加到request的config里 
   * @since 0.0.10
   */
  extra?: any
}

/**
 * http配置参数
 * @extends AxiosRequestConfig
 */
interface HttpOption<R> extends AxiosRequestConfig {
  /** 设备id，生成一个存储在本地 */
  deviceId: string
  /** 软件版本号 */
  ver: string
  /** 渠道编码。微信：wx */
  cc: string
  /** 接口版本号。日期形式：YYYYMMDD */
  v: string
  /** 自定义配置公共请求的参数 */
  commonParamsFn?: (config: HttpRequestConfig) => object
  /** 请求成功的拦截处理 */
  successInterceptor?: (value: R, config: HttpRequestConfig) => R | Promise<R>
  /** 出错的处理 */
  onError?: (err: HttpError, config?: HttpRequestConfig) => void
  /** 
   * 重连次数
   * @since 0.0.11
   */
  retry?: number
  /** 
   * 重连间隔时间，默认`100ms`
   * @since 0.0.11
   */
  retryInterval?: number
  /** 
   * 签名方法，返回需要传输的签名key和签名生成的字符串
   * @since 0.0.11
   */
  sign?: (params: object) => { key: string, value: string }
  /**
   * bigint处理数据用
   * @since 2021.08.13
   */
  transformResponse?: any[]
  /**
   * 响应数据类型
   * 使用bigint插件时，需要设置为`text`
   * @since 2021.08.13
   */
  responseType?: ResponseType
}

/**
 * API响应默认格式
 */
interface APIResponse<T = any> {
  /** 返回码 */
  code: number
  /** 返回数据 */
  result: T
  /** 提示信息 */
  msg: string
}

/**
 * Http请求封装
 * 
 * 使用
 * 
 */
export class Http<R = APIResponse> {
  /**
   * 默认参数
   */
  private defaultOptions = {
    // 默认请求头
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    // 默认超时时间：10秒
    timeout: 10000,
    // 默认返回数据类型：json
    responseType: 'json',
    // 正确响应的状态码
    validateStatus (status: number) {
      return status >= 200 && status < 300
    }
  }
  /**
   * 请求配置
   */
  private options: HttpOption<R>
  /**
   * axios实例对象
   */
  private _axios: AxiosInstance

  constructor (options: HttpOption<R>) {
    this.options = { ...this.defaultOptions, ...options } as any

    if (this.options.transformResponse) {
      axios.defaults.transformResponse = this.options.transformResponse
    }
    this._axios = axios.create(this.options)

    // 设置响应拦截
    this._axios.interceptors.response.use(
      this.successInterceptor.bind(this),
      this.errorInterceptor.bind(this)
    )
  }

  /**
   * 请求成功的拦截处理
   * 
   * @param value axios返回数据
   */
  private successInterceptor (value: AxiosResponse<R>) {
    if (this.options.successInterceptor) {
      const result = this.options.successInterceptor(value.data, value.config)
      // fix 拦截后仍然执行then的问题 2020.05.21
      if (result instanceof Promise) {
        return result.then((res) => {
          value.data = res
          return value
        })
      } else {
        value.data = result
        return value
      }
    } else {
      return value
    }
  }

  /**
   * 错误拦截处理
   * 
   * 如果需要需要统一处理错误信息，在`options`中传入`onError`方法
   * @param err 错误信息
   */
  private errorInterceptor (err: any): any {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.msg = '请求错误(400)'
          break
        case 401:
          err.msg = '未授权，请重新登录(401)'
          break
        case 403:
          err.msg = '拒绝访问(403)'
          break
        case 404:
          err.msg = '请求出错(404)'
          break
        case 408:
          err.msg = '请求超时(408)'
          break
        case 500:
          err.msg = '服务器错误(500)'
          break
        case 501:
          err.msg = '服务未实现(501)'
          break
        case 502:
          err.msg = '网络错误(502)'
          break
        case 503:
          err.msg = '服务不可用(503)'
          break
        case 504:
          err.msg = '网络超时(504)'
          break
        case 505:
          err.msg = 'HTTP版本不受支持(505)'
          break
        default:
          err.msg = `连接出错(${err.response.status})!`
      }
    } else {
      err.msg = '连接服务器失败!'
    }

    // 添加连接失败重连机制。2020.01.09
    const config = err.config
    if (!config) {
      if (this.options.onError) {
        this.options.onError(err)
      }
      return Promise.reject(err)
    }

    config.__retryCount = config.__retryCount || 0
    if (config.__retryCount >= (this.options.retry || 0)) {
      if (this.options.onError) {
        this.options.onError(err, config)
      }
      return Promise.reject(err)
    }

    config.__retryCount += 1

    const backoff = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('')
      }, this.options.retryInterval || 100)
    })

    return backoff.then(() => {
      return this._axios.request(config)
    })
  }

  /**
   * 获取平台信息
   */
  public static get platform () {
    const ua = navigator.userAgent
    if (isWeixin()) {
      return 1
    } else if (/WeiBo/i.test(ua)) {
      return 2
    } else if (/QQ/i.test(ua)) {
      return 3
    } else if (/Mobile/i.test(ua)) {
      return 5
    } else {
      return 4
    }
  }

  /**
   * 获取公共参数
   */
  private getCommonParams (): HttpCommonParams {
    const networkType = getNetworkType()
    const ua = new UAParser(navigator.userAgent)

    let os = 3
    const osName = ua.getOS().name
    if (osName) {
      if (osName.toLowerCase() === 'android') {
        os = 2
      } else if (osName.toLowerCase() === 'ios') {
        os = 1
      }
    }

    const params: HttpCommonParams = {
      did: this.options.deviceId,
      cc: this.options.cc,
      os,
      osv: ua.getOS().version || '',
      ver: this.options.ver,
      deviceId: this.options.deviceId,
      bro: ua.getBrowser().name || '',
      brov: ua.getBrowser().version || '',
      lat: 0,
      lon: 0,
      net: networkType.type,
      na: networkType.generation,
      platform: Http.platform,
      requestId: v1().replace(/-/g, ''),
      v: this.options.v,
      t: new Date().getTime()
    }

    return params
  }

  /**
   * 调用request请求
   * 
   * @param config 请求方法
   */
  public request (config: HttpRequestConfig): Promise<R> {
    const params: any = {}
    const paramsTmp: any = {
      ...this.getCommonParams(),
      ...(this.options.commonParamsFn ? this.options.commonParamsFn(config) : {}),
      ...config.params
    }
    // 去除空的参数。微信签名接口(PHP版本)，如果有空的参数，无法正常返回。
    Object.keys(paramsTmp).forEach((key) => {
      if (paramsTmp[key] !== '' && paramsTmp[key] !== undefined && paramsTmp[key] !== null) {
        params[key] = paramsTmp[key]
      }
    })

    // 签名处理。 2020.01.09
    if (this.options.sign) {
      const signResult = this.options.sign({ ...params })
      params[signResult.key] = signResult.value
    }

    config.method = config.method || 'get'
    const method = config.method.toLowerCase()
    const headers = config.headers || this.options.headers
    if (method === 'post') {
      // 如果是post,清楚query参数 v0.0.13
      config.params = {}
      if ((headers as any)['Content-Type'] === 'multipart/form-data') {
        if (config.data instanceof FormData) {
          Object.keys(params).forEach((key) => {
            config.data.append(key, params[key])
          })
        }
      } else {
        config.data = qs.stringify(params)
      }
    } else if (method === 'get') {
      config.params = params
    }

    return this._axios.request(config).then((res) => {
      return res.data
    })
  }

  /**
   * get 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  public get (url: string, params?: object) {
    return this.request({
      method: 'get',
      url,
      params
    })
  }

  /**
   * post 请求
   * @param url 请求地址
   * @param data 请求数据
   */
  public post (url: string, data?: object) {
    return this.request({
      method: 'post',
      url,
      params: data
    })
  }

}

export default Http
