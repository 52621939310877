
import { Vue, Component } from 'vue-property-decorator';
import { Header, Footer } from '@/components';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class Home extends Vue {}
