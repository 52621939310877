import uuid from 'uuid'
import { Message } from 'view-design'
import { isEmpty, trim } from '@/utils/util'
import store from '@/store'
import { LocalStore } from '@/utils/storage'
import Http from '@/utils/http'

const DID_KEY = 'ZY_WWW_DID'

if (!LocalStore.get(DID_KEY)) {
  LocalStore.set(DID_KEY, uuid())
}

const http = new Http({
  baseURL: process.env.VUE_APP_P_API,
  cc: 'web',
  ver: '4.08',
  v: ' 20191231',
  deviceId: LocalStore.get(DID_KEY),
  timeout: 15000,
  commonParamsFn: () => {
    const operator: any = {}
    if (!isEmpty(store.getters.getUserInfo)) {
      operator.uuToken = store.getters.getUserInfo.uuToken
      operator.userId = store.getters.getUserInfo.userId
    }

    let params: any = {
      ...operator
    }

    params = trim(params)
    return params
  },
  successInterceptor: (res) => {
    if (res.code !== 1000) {
      (Message as any).error(res.msg)
      if (res.code === 4996 || res.code === 4997) {
        // LocalStore.clear()
        store.dispatch('setUserInfo', null)
        store.dispatch('setLoginVisible', true)
      }
      return Promise.reject(res)
    }
    return res
  },
  onError: (err) => {
    (Message as any).error(err.msg)
  }
})
export default http
