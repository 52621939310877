
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'App',
})
export default class App extends Vue {
  @Watch('$route')
  private UWebChange() {
    const _czc = (window as any)._czc || [];
    if (_czc) {
      const contentUrl = this.$route.path;
      const refererUrl = location.origin + sessionStorage.getItem('preRoutePath');
      _czc.push(['_trackPageview', contentUrl, refererUrl]);
    }
  }
  /**
   * name
   */
  public name() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
      window.location.replace('https://user.sanjiahospital.com/'); //跳转后没有后退功能
    }
  }
  mounted() {
    this.name();
  }
}
