/**
 * 本地存储可配置项
 */
interface LocalStoreOptions {
  /** 过期时间, 单位：秒 */
  expire?: number,
  /** 前缀 */
  prefix?: string
}

/**
 * 本地存储类
 * 
 * 可实例化调用
 * 
 * 也可直接调用静态方法
 * 
 * 可配参数：prefix: 统一的key前缀；expire:过期时间，单位秒
 */
export class LocalStore {
  /**
   * key前缀
   * 
   * 例如，
   * 
   * 
   * `store.set('test','test')`
   * 
   */
  private _prefix = ''
  /**
   * 构造函数
   * @param prefix 前缀, 默认为空
   */
  constructor (prefix = '') {
    this._prefix = prefix
  }

  /**
   * 设置本地存储
   * @param key 存储键
   * @param value 存储值
   * @param expire 过期时间，单位 秒
   */
  public set (key: string, value: string | number | boolean | object, expire = -1) {
    LocalStore.set(key, value, {
      expire,
      prefix: this._prefix
    })
  }

  /**
   * 获取本地存储
   * @param key 键值
   */
  public get (key: string) {
    return LocalStore.get(key, this._prefix)
  }

  /**
   * 清除本地存储
   */
  public clear () {
    LocalStore.clear(this._prefix)
  }

  /**
   * 删除本地存储项目
   * @param key 键值
   */
  public remove (key: string) {
    LocalStore.remove(key, this._prefix)
  }

  /**
   * 设置本地存储(静态方法)
   * 
   * 如果有传入前缀，则将前缀和key拼接
   * 
   * @param key 键值
   * @param value 存储值
   * @param options expire: 过期时间(单位：秒)，prefix：前缀，可选  
   */
  public static set (key: string, value: string | number | boolean | object, options: LocalStoreOptions = {}) {
    const {expire = -1, prefix = ''} = options
    let _val = ''
    if (expire && expire !== -1) {
      const expireValue = {
        __expire: Date.now() + expire * 1000,
        value
      }
      _val = JSON.stringify(expireValue)
    } else {
      if (typeof value !== 'string') {
        _val = JSON.stringify(value)
      } else {
        _val = value
      }
    }
    window.localStorage.setItem(prefix + key, _val)
  }

  /**
   * 获取本地存储(静态方法)
   * 
   * 如果有传入前缀，则将前缀和key拼接
   * 
   * @param key 键值
   * @param prefix key前缀，如果不同的应用可以设置为不同的前缀
   */
  public static get (key: string, prefix = '') {
    const _key = prefix + key
    let value: any = window.localStorage.getItem(_key)
    if (value !== null) {
      try {
        value = JSON.parse(value)
        if (value.__expire) {
          if (Date.now() > value.__expire) {
            window.localStorage.removeItem(_key)
            return null
          } else {
            return value.value
          }
        } else {
          return value
        }
      } catch (e) {
        return value
      }
    }
  }

  /**
   * 清除本地存储(静态方法)
   * 
   * 如果有传入前缀，则只清除带前缀的key
   * 
   * @param prefix key前缀，如果不同的应用可以设置为不同的前缀
   */
  public static clear (prefix = '') {
    if (prefix === '') {
      window.localStorage.clear()
    } else {
      const keys = Object.keys(window.localStorage)
      keys.forEach((key) => {
        if (key.indexOf(prefix) > -1) {
          window.localStorage.removeItem(key)
        }
      })
    }
  }

  /**
   * 删除本地存储项目
   * @param key 键值
   * @param prefix 前缀，默认无
   */
  public static remove (key: string, prefix = '') {
    window.localStorage.removeItem(prefix + key)
  }
}
