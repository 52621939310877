import Vue from 'vue'
const SEX = ['女', '男', '未知']
const sex = (code: number) => {
  return SEX[code]
}
// const doctorRate = (starNum: number) => {
//   const starNumber = parseFloat('0.' + starNum.toString().split('.')[1])
//   if (0 <= starNumber && starNumber < 0.3) {
//     return parseFloat(starNum.toString().split('.')[0])
//   } else if (0.3 <= starNumber && starNumber < 0.8) {
//     return parseFloat(starNum.toString().split('.')[0]) + 0.5
//   } else {
//     return parseFloat(starNum.toString().split('.')[0]) + 1
//   }
// }
// // 医生职称
// const doctorJob = (code: string, list: any[]) => {
//   let value = ''
//   list.forEach((i: any) => {
//     if (parseFloat(code) === parseFloat(i.code)) {
//       value = i.value
//     }
//   })
//   return value
// }
// // 优惠券种类
// const couponCategory = (code: number) => {
//   let value = ''
//   switch (code) {
//     case 1:
//       return value = '代金券'
//       break
//     case 2:
//       return value = '满减券'
//       break
//     case 3:
//       return value = '折扣券'
//       break
//   }
// }
// // 优惠券类型
// const couponType = (code: number) => {
//   // 类型 1 通用 2图文 3电话 4视频 5 机构
//   switch (code) {
//     case 1:
//       return '全场通用'
//     case 2:
//       return '图文咨询'
//     case 3:
//       return '电话咨询'
//     case 4:
//       return '视频咨询'
//     case 5:
//       return '机构咨询'
//     case 6:
//       return '在线购药'
//     case 7:
//       return '基因检测'
//     case 8:
//       return '咨询服务'
//     case 9: // V4.03 添加。 2019.10.15 
//       return '极速提问'
//   }
// }
// // 保留小数点后两位的过滤器，尾数不四舍五入
// const keepTwoNum = (num: number) => {
//   return num.toFixed(2)
// }
// /**
//  * 根据生日计算年龄
//  * @param birth 生日
//  * @param from 开始日期
//  */
// const age = (birth: number, from?: number) => {
//   const birthDayTime = new Date(birth).getTime()
//   const nowTime = from || new Date().getTime()
//   return Math.round((nowTime - birthDayTime) / 31536000000)
// }
// const APPLY_STATUS: any = {
//   0: '草稿',
//   1: '待确认',
//   2: '已拒绝',
//   3: '待确认',
//   4: '已确认',
//   5: '已取消'
// }
// const medicineApplyStatus = (status: string) => {
//   return APPLY_STATUS[status]
// }
// /** 字典过滤器 */
// const dicFilter = (code: string, list: any[]) => {
//   const data = list.find((item) => {
//     return item.code + '' === code + ''
//   })
//   return data ? data.value : code
// }
// /**
//  * 赠送类型
//  * @param code 赠送类型
//  */
// const giveType = (code: number) => {
//   switch (code) {
//     case 1:
//       return '非赠送'
//     case 2:
//       return '随访赠送'
//     case 3:
//       return '扫码赠送'
//     case 4:
//       return '购药赠送'
//   }
// }
// /**
//  * 图文订单状态
//  * @param orderStatus 订单状态
//  * @param payStatus 支付状态
//  */
// const consultOrderStatus = (orderStatus: number, payStatus: number) => {
//   switch (orderStatus) {
//     // 订单未支付
//     case 0:
//       if (payStatus === 1) {
//         return '支付失败'
//       } else {
//         return '未支付'
//       }
//     case 1:
//       return '已关闭'
//     case 2:
//       return '服务中'
//     case 4:
//       return '服务结束'
//     case 5:
//       return '退款中'
//     case 6:
//       return '已退款'
//   }
// }
// /**
//  * 图文咨询服务时效
//  * @param time 时间戳
//  */
// const consultServiceTime = (time?: number) => {
//   if (!time) {
//     return '48小时'
//   } else {
//     const hour = Math.floor((Date.now() - time) / 60 / 60 / 1000)
//     if (hour === 0) {
//       return '不足1小时'
//     } else {
//       return '剩余' + hour + '小时'
//     }
//   }
// }
// /**
//  * 支付类型
//  * @param type 支付类型
//  */
// const payType = (type: string) => {
//   switch (type) {
//     case 'wxPay':
//       return '微信'
//   }
// }
// /** 药品订单状态 */
// const MEDICINE_ORDER_STATUS: { [key: string]: string } = {
//   3: '审核中', 4: '审核未通过', 5: '已过期', 6: '待下单', 7: '未支付', 8: '待发货',
//   9: '已发货', 10: '已完成', 11: '退款中', 12: '已退款', 13: '已关闭'
// }
// const medicineOrderStatus = (orderStatus: number) => {
//   return MEDICINE_ORDER_STATUS[orderStatus]
// }
// /** 咨询服务类型 */
// const serviceType = (type: string) => {
//   type = type + ''
//   switch (type) {
//     case '1':
//       return '图文咨询'
//     case '2':
//       return '电话咨询'
//     case '3':
//       return '视频咨询'
//     case '4':
//       return '极速提问'
//   }
// }
// /**
//  * 用法用量，如果是睡前，则去掉服用方法
//  */
// const drugUseInfo = (useInfo: string, takeTime: string) => {
//   if (takeTime === '4') { 
//      return useInfo.replace(/餐前|餐后|与餐同服/g, '') 
//   }
//   return useInfo
// }
Vue.filter('sex', sex)
// Vue.filter('doctorRate', doctorRate)
// Vue.filter('doctorJob', doctorJob)
// Vue.filter('couponCategory', couponCategory)
// Vue.filter('couponType', couponType)
// Vue.filter('keepTwoNum', keepTwoNum)
// Vue.filter('age', age)
// Vue.filter('medicineApplyStatus', medicineApplyStatus)
// Vue.filter('dicFilter', dicFilter)
// Vue.filter('giveType', giveType)
// Vue.filter('consultOrderStatus', consultOrderStatus)
// Vue.filter('consultServiceTime', consultServiceTime)
// Vue.filter('payType', payType)
// Vue.filter('medicineOrderStatus', medicineOrderStatus)
// Vue.filter('serviceType', serviceType)
// Vue.filter('drugUseInfo', drugUseInfo)
