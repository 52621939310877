import Vue from 'vue'
import Vuex from 'vuex'
import { State } from '@/interface'
import { isEmpty } from '@/utils/util'
import { LocalStore } from '@/utils/storage'

Vue.use(Vuex)

const USERINFO_KEY = 'ZY_WWW_USERINFO'
const GLOBAL_CONFIG = 'ZY_WWW_GLOBALCONFIG'

const store = new Vuex.Store<State>({
  state: {
    isMobile: document.documentElement.clientWidth < 750,
    isFooterFixed: true,
    footerMarginBottom: false,
    userInfo: LocalStore.get(USERINFO_KEY),
    globalConfig: LocalStore.get(GLOBAL_CONFIG),
    loginVisible: false,
    clinicJson: LocalStore.get('clinicJson'),
    doctorId: LocalStore.get('doctorId')
  },
  getters: {
    getUserInfo: (_state: any) => {
      if (isEmpty(_state.userInfo)) {
        return LocalStore.get(USERINFO_KEY)
      } else {
        return _state.userInfo
      }
    },
    getClinicJson: (_state: any) => {
      if (isEmpty(_state.clinicJson)) {
        return LocalStore.get('clinicJson')
      } else {
        return _state.clinicJson
      }
    },
    getDoctorId: (_state: any) => {
      if (isEmpty(_state.doctorId)) {
        return LocalStore.get('doctorId')
      } else {
        return _state.doctorId
      }
    }
  },
  mutations: {
    footerFixed (status, isFooterFixed) {
      status.isFooterFixed = isFooterFixed
    },
    footerMarginBottom (status, isFooterMarginBottom) {
      status.footerMarginBottom = isFooterMarginBottom
    },
    /** 用户信息 */
    setUserInfo (status, userInfo) {
      status.userInfo = userInfo
    },
    /** 全局配置 */
    setGlobalConfig: (state, config) => {
      state.globalConfig = config
    },
    setLoginVisible (status, loginVisible) {
      status.loginVisible = loginVisible
    },
    setClinicJson (status, clinicJson) {
      status.clinicJson = clinicJson
    },
    setDoctorId (status, doctorId) {
      status.doctorId = doctorId
    }
  },
  actions: {
    setLoginVisible ({ commit }, bool: boolean) {
      commit('setLoginVisible', bool)
    },
    setGlobalConfig: ({ commit }, data: any) => {
      commit('setGlobalConfig', data)
    },
    setUserInfo: ({ commit }, data: any) => {
      // LocalStore.set(USERINFO_KEY, data, { expire: 60 * 30 })
      LocalStore.set(USERINFO_KEY, data)
      commit('setUserInfo', data)
    },
    setClinicJson: ({ commit }, data: any) => {
      LocalStore.set('clinicJson', data)
      commit('setClinicJson', data)
    },
    setDoctorId: ({ commit }, data: any) => {
      LocalStore.set('doctorId', data)
      commit('setDoctorId', data)
    }
  },
  modules: {}
})

window.onresize = () => {
  store.state.isMobile = document.documentElement.clientWidth < 750
}
export default store
