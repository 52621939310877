
import { Vue, Component, Watch } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class HeaderTop extends Vue {
  public mobileMenuShow = false;
  public routeList: any[] = [
    {
      path: '/',
      title: '首页',
    },
    // {
    //   path: '/consult',
    //   title: '我想咨询',
    // },
    /*   {
      path: '/doctor',
      title: '我是医生'
    }, */
    // {
    //   path: '/medicineList',
    //   title: '专家团队',
    // },
    {
      path: '/doctorList',
      title: '专家团队',
    },
    // {
    //   path: '/partner',
    //   title: '合作伙伴'
    // },
    {
      path: '/about',
      title: '特色优势',
    },
  ];
  public get activeName() {
    const route = this.routeList.find((item) => {
      return item.path === this.$route.path;
    });
    return route ? route.title : '';
  }
  public login() {
    this.$store.dispatch('setLoginVisible', true);
  }
  public logout() {
    this.$Modal.confirm({
      title: '是否退出登录，退出登录后将返回到首页',
      onOk: () => {
        this.$store.dispatch('setUserInfo', null);
        this.$router.push('/');
      },
    });
  }
}
