
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({name: 'OssUpload'})
export default class OssUpload extends Vue {

  @Prop({default: false}) private disabled?: boolean
  @Prop() private beforeRead?: any
  @Prop() private onChangeFile?: any
  @Prop({default: 'image/*'}) public accept?: string
  @Prop({default: 'dataUrl'}) private resultType ?: string
  @Prop({default: Number.MAX_VALUE}) public maxSize ?: number

  protected onChange (event: any) {
    const _this: any = this
    let {files} = event.target
    if (this.disabled || !files.length) {
      return
    }
    files = files.length === 1 ? files[0] : [].slice.call(files, 0)
    if (!files || (this.beforeRead && !this.beforeRead(files))) {
      return
    }
    if (Array.isArray(files)) {
      Promise.all(files.map(_this.readFile)).then((contents) => {
        let oversize = false
        const payload = files.map((file: File, index: any) => {
          if (file.size > _this.maxSize) {
            oversize = true
          }
          return {
            file: files[index],
            content: contents[index]
          }
        })
        this.onAfterRead(payload, oversize)
      })
    } else {
      this.readFile(files).then((content: any) => {
        this.onAfterRead(
          {file: files, content},
          files.size > _this.maxSize
        )
      })
    }
  }

  protected readFile (file: File) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (event: any) => {
        resolve(event.target.result)
      }
      if (this.resultType === 'dataUrl') {
        reader.readAsDataURL(file)
      } else if (this.resultType === 'text') {
        reader.readAsText(file)
      }
    })
  }

  protected onAfterRead (files: any, oversize: any) {
    if (oversize) {
      this.$emit('oversize', files)
    } else {
      if (this.onChangeFile) {
        this.onChangeFile(files)
      }
      if (this.$refs.input) {
        (this.$refs.input as any).value = ''
      }
    }
  }
}
