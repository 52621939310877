import { encrypt, decrypt } from './encrypt'
import { Vue } from 'vue-property-decorator'
export const isEmpty = (keys: any): boolean => {
  if (typeof keys === 'string') {
    keys = keys.replace(/\"|&nbsp;|\\/g, '').replace(/(^\s*)|(\s*$)/g, '')
    if (keys === '' || keys === null || keys === 'null' || keys ===
      'undefined') {
      return true
    } else {
      return false
    }
  } else if (typeof keys === 'undefined') {  // 未定义
    return true
  } else if (typeof keys === 'number') {
    return false
  } else if (typeof keys === 'boolean') {
    return false
  } else if (typeof keys === 'object' && !Array.isArray(keys)) {
    if (JSON.stringify(keys) === '{}') {
      return true
    } else if (keys === null) {
      return true
    } else {
      return false
    }
  } else if (keys instanceof Array && keys.length === 0) {
    return true
  } else {
    return false
  }
}

export const trim = (obj: any) => {
  if (typeof obj === 'object') {
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/(^\s*)|(\s*$)/g, '')
      }
    }
  } else if (typeof obj === 'string') {
    obj = obj.replace(/(^\s*)|(\s*$)/g, '')
  }
  return obj
}
// // 设置cookie
// export const setCookie = (
//   cname: string, cvalue: string, days: number, passKey: string) => {
//   const d = new Date()
//   d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000))
//   const expires = 'expires=' + d.toUTCString()
//   // @ts-ignore
//   document.cookie = cname + '=' + encrypt(escape(cvalue), passKey) + '; ' +
//     expires
// }

// // 读取cookie
// export const getCookie = (cname: string, passKey: string) => {
//   const name = cname + '='
//   const ca = document.cookie.split(';')
//   for (let c of ca) {
//     while (c.charAt(0) === ' ') {
//       c = c.substring(1)
//     }
//     if (c.indexOf(name) !== -1) {
//       const cnameValue = unescape(c.substring(name.length, c.length))
//       // @ts-ignore
//       return decrypt(cnameValue, passKey)
//     }
//   }
//   return ''
// }
// // 清除cookie
// export const clearCookie = (cname: string, passKey: string) => {
//   setCookie(cname, '', -1, passKey)
// }

// export const removeStore = (name: string): void => {
//   if (!name || !window.localStorage) return
//   window.localStorage.removeItem(name)
// }

// export const clearStore = (): void => {
//   if (!window.localStorage) return
//   window.localStorage.clear()
// }

// export const keepTwoFloat = (num: number): any => {
//   const _num: any = Math.round(num * 100) / 100
//   const _arr: string[] = _num.toString().split('.')
//   if (_arr.length === 1) {
//     return _num.toString() + '.00'
//   }
//   if (_arr.length > 1) {
//     if (_arr[1].length < 2) {
//       return _num.toString() + '0'
//     }
//     return _num.toString()
//   }
//   return _num
// }

// export const getTwoFixedNum = (num: number): string => {
//   return Number(num).toFixed(2)
// }

// export const getComStyle = (el: any, style: any): number => {
//   return parseInt(window.getComputedStyle(el, null)[style], 0)
// }

// export const getDeviceRatio = (): number => {
//   //    const isAndroid = window.navigator.appVersion.match(/android/gi)
//   // const isIPhone: RegExpMatchArray = window.navigator.appVersion.match(
//   const isIPhone = window.navigator.appVersion.match(
//     /iphone/gi)
//   const devicePixelRatio: number = window.devicePixelRatio
//   let dpr = 1
//   if (isIPhone) {
//     // iOS下，对于2和3的屏，用2倍的方案，其余的用1倍方案
//     if (devicePixelRatio >= 3) {
//       dpr = 3
//     } else if (devicePixelRatio >= 2) {
//       dpr = 2
//     } else {
//       dpr = 1
//     }
//   } else {
//     // 其他设备下，仍旧使用1倍的方案
//     dpr = 1
//   }
//   return dpr
// }
// /*
//  * 事件处理函数
//  * */
// export const eventUtil = {
//   addHandler (element: HTMLElement | any, type: string, handler: any) {
//     if (element.addEventListener) {
//       element.addEventListener(type, handler, false)
//     } else if (element.attachEvent) {
//       element.attachEvent('on' + type, handler)
//     } else {
//       element['on' + type] = handler
//     }
//   },
//   removeHandler (element: HTMLElement | any, type: string, handler: any) {
//     if (element.removeEventListener) {
//       element.removeEventListener(type, handler, false)
//     } else if (element.detachEvent) {
//       element.detachEvent('on' + type, handler)
//     } else {
//       element['on' + type] = null
//     }
//   },
//   getEvent (event: HTMLElement) {
//     return event ? event : window.event
//   },
//   getType (event: any) {
//     return event.type
//   },
//   // 获取事件源
//   getElement (event: any) {
//     return event.target || event.srcElement
//   },
//   // 阻止默认事件比如a链接跳转
//   preventDefault (event: any) {
//     if (event.preventDefault) {
//       event.preventDefault()
//     } else {
//       event.returnValue = false
//     }
//   },
//   // 阻止事件冒泡
//   stopPropagation (event: any) {
//     if (event.stopPropagation) {
//       event.stopPropagation()
//     } else {
//       event.cancelBubble = true
//     }
//   }
// }

// export const haddleFullScreen = (fullFlag: boolean): boolean => {
//   const docuMent: Document | any = document
//   const element: HTMLElement | any = document.documentElement
//   if (fullFlag) {
//     if (docuMent.exitFullscreen) {
//       docuMent.exitFullscreen()
//     } else if (docuMent.webkitCancelFullScreen) {
//       docuMent.webkitCancelFullScreen()
//     } else if (docuMent.mozCancelFullScreen) {
//       docuMent.mozCancelFullScreen()
//     } else if (docuMent.msExitFullscreen) {
//       docuMent.msExitFullscreen()
//     }
//   } else {
//     if (element.requestFullscreen) {
//       element.requestFullscreen()
//     } else if (element.webkitRequestFullScreen) {
//       element.webkitRequestFullScreen()
//     } else if (element.mozRequestFullScreen) {
//       element.mozRequestFullScreen()
//     } else if (element.msRequestFullscreen) {
//       // IE11
//       element.msRequestFullscreen()
//     }
//   }
//   return !fullFlag
// }

// const fill2Num = (num: number) => {
//   return num > 9 ? '' + num : '0' + num
// }
// /**
//  * 获取规范的文件名
//  * 日期/文件名，例：20181011/20181011121222.jpg
//  * @param fileName 文件名，例：abc.jpg
//  * @param useOriginName 是否保持原有的文件名，默认为false
//  * @returns string
//  */
// export const dateFileName = (fileName: string, useOriginName = false): string => {
//   const date = new Date()
//   const Y = date.getFullYear()
//   const M = (date.getMonth() + 1 < 10
//     ? '0' + (date.getMonth() + 1)
//     : date.getMonth() + 1)
//   const D = fill2Num(date.getDate())
//   const h = fill2Num(date.getHours())
//   const m = fill2Num(date.getMinutes())
//   const s = fill2Num(date.getSeconds())
//   const arr = fileName.split('.')
//   const folder = `${Y}${M}${D}`
//   const newName = `${Y}${M}${D}${h}${m}${s}.${arr[arr.length - 1]}`
//   return `${folder}/${useOriginName ? fileName : newName}`
// }
// // 对象彻底冻结的函数
// export const constantize = (obj: object): void => {
//   Object.freeze(obj)
//   for (const item of Object.keys(obj)) {
//     if (typeof item === 'object') {
//       constantize(item)
//     }
//   }
// }
// // 获取顶级函数
// export const global = (): any => {
//   if (typeof self !== 'undefined') { return self }
//   if (typeof window !== 'undefined') { return window }
//   if (typeof global !== 'undefined') { return global }
//   throw new Error('unable to locate global object')
// }
// export const themeType = (len: number, bool = true) => {
//   const colorList: string[] = ['primary', 'info', 'success', 'warning', 'error'] // error
//   const colorSelect: number[] = []
//   if (len > 0) {
//     for (let i = 0; i < len; i++) {
//       if (bool) {
//         const randerNum = Math.floor(Math.random() * colorList.length)
//         if (colorSelect.length > 0) {
//           if (randerNum !== colorSelect[colorSelect.length - 1]) {
//             colorSelect.push(randerNum)
//           } else {
//             i--
//           }
//         } else {
//           colorSelect.push(randerNum)
//         }
//       } else {
//         if (i < colorList.length) {
//           colorSelect.push(i)
//         } else {
//           colorSelect.push(i % colorList.length)
//         }
//       }
//     }
//   }
//   return colorSelect.map((i: number) => {
//     return colorList[i]
//   })
// }
// export const imgsDownLoad = (args: string[], fn: any): any => {
//   args.map((i) => {
//     const dom: any = document.createElement('a')
//     dom.setAttribute('download', 'downloadImages')
//     dom.setAttribute('href', i)
//     document.body.appendChild(dom)
//     fn(dom)
//   })
// }
// // 判断是否为Trident内核浏览器(IE等)函数
// export const browserIsIe = () => {
//   const _window = (window as any)
//   if (!!_window.ActiveXObject || 'ActiveXObject' in _window) {
//     return true
//   } else {
//     return false
//   }
// }
// export const exportExcel = (columns: any[], datas: any) => {
//   const titleList: any[] = []
//   const keyList: any[] = []
//   const dataList: any[] = datas
//   for (const clos of columns) {
//     titleList.push(clos.title)
//     keyList.push(clos.key)
//   }
//   const initObj = (list: any[]) => {
//     list.forEach((val: any) => {
//       for (const key of Object.keys(val)) {
//         if (val.hasOwnProperty(key)) {
//           val[key] = val[key].toString()
//         }
//       }
//     })
//     return list
//   }
//   return {
//     title: titleList,
//     key: keyList,
//     data: initObj(dataList),
//     autoWidth: true,
//     filename: new Date().toDateString()
//   }
// }

// export const downloadFile = (fileName: string, url: string) => {
//   const ieDown = () => {
//     window.open(url)
//   }

//   const isIE = () => {
//     const explorer = window.navigator.userAgent
//     return explorer.indexOf('MSIE') >= 0 || explorer.indexOf('Trident/7.0') >=
//       0 || explorer.indexOf('Edge') >= 0
//   }
//   if (isIE()) {
//     ieDown()
//   } else {
//     const aLink = document.createElement('a')
//     const evt = document.createEvent('MouseEvents')
//     // var evt = document.createEvent("HTMLEvents")
//     evt.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false,
//       false, false, false, 0, null)
//     aLink.download = fileName
//     aLink.href = url
//     aLink.dispatchEvent(evt)
//   }
// }
// export const unique = (arr: any[]) => {
//   const hash: any[] = []
//   for (const i of arr) {
//     if (hash.indexOf(i) === -1) {
//       hash.push(i)
//     }
//   }
//   return hash
// }

// export const install = (_vue: any, list: any[]) => {
//   return function installInit () {
//     list.map((i: any) => {
//       _vue.prototype['$' + i.key] = i.value
//     })
//   }
// }

// export const initFormData = (formData: any, ...obj: any) => {
//   for (const items of obj) {
//     for (const key of Object.keys(items)) {
//       formData.append(key, items[key])
//     }
//   }
//   return formData
// }

// /**
//  * 事件总线通信
//  */
// export const eventBus = new Vue()
