
import { Vue, Component } from 'vue-property-decorator';
import { Footer } from 'element-ui';
@Component({
 components: {
  [Footer.name]: Footer,
 },
})
export default class CFooter extends Vue {
 public year = new Date().getFullYear();
 public currentDomain = window.location.host;
 protected mounted() {
  console.log(this.$store.state.isFooterFixed);
 }
}
