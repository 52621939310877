
import { Component, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import OssUpload from '@/components/oss-upload/index.vue';
import { CommonService } from '@/api/common';
import globalSetting from '@/utils/globalSetting';

@Component({
  name: 'OssSingleUpload',
  components: {
    OssUpload,
  },
})
export default class OssSingleUpload extends Vue {
  @Model('input') private value!: any[];
  @Prop({
    default: (): any[] => {
      return [];
    },
  })
  protected imagesList!: any[]; // 图片url数组
  @Prop({ default: 0 }) protected count!: number; // 上传的图片张数
  protected modalBool = false;
  protected flagIndex = 0;
  protected interfaceImgPath = '';
  protected imgList: any[] = [];
  private imgDesc = '添加照片（选填项，最多上传5张）';

  @Watch('value', { immediate: false, deep: true })
  private valueChange() {
    if (this.value) {
      this.imgList = this.value;
    }
  }

  @Watch('imagesList')
  private imgChange() {
    this.imgList = this.imagesList;
  }

  protected handleView(index: number) {
    this.flagIndex = index;
    this.modalBool = true;
  }
  @Prop({ default: 'temp' }) protected folderName!: string; // 上传的图片文件夹
  private async created() {
    const config: any = await globalSetting.getSetting();
    this.interfaceImgPath = config.urlList.imgUrl; // 图片前缀
  }
  protected onChangeFile(file: any) {
    const _this: any = this as any;
    if (_this.$listeners.upDateChange) {
      _this.$emit('upDateChange', file);
    } else {
      /* CommonService.getStsToken().then((res: any) => {
        const oss = new AliOssClient(res.result)
        oss.putImage(this.folderName, file.file).then((ret: any) => {
          _this.$Message.success('上传成功')
          _this.imgList.push(ret)
          this.$emit('input', this.imgList)
        })
      }) */
    }
  }

  protected closeHandler(index: number) {
    this.imgList.splice(index, 1);
    if (this.$listeners.closeEvent) {
      this.$emit('closeEvent', this.imgList);
    } else {
      this.$emit('input', this.imgList);
    }
  }
}
