
/**
 * 是否在微信客户端
 */
export function isWeixin () {
  return /MicroMessenger/i.test(navigator.userAgent)
}

/**
 * 是否是安卓系统
 */
export function isAndroid () {
  return /Android/i.test(navigator.userAgent)
}

/**
 * 是否是IOS系统
 */
export function isIos () {
  return /ios|iphone|ipad|ipod/gi.test(navigator.userAgent)
}

/**
 * 是否是移动端
 */
export function isMobile () {
  return /Mobile/i.test(navigator.userAgent)
}

/**
 * 网络类型
 */
interface NetworkType {
  /** 运营商。 0:未知、1:移动、2:联通、3:电信、4:热点/wifi */
  type: 0 | 1 | 2 | 3 | 4
  /** 第几代网络。 0:未知、2:2G、3:3G、4:4G、5:5G */
  generation: 0 | 2 | 3 | 4 | 5
}

/**
 * 获取网络类型
 */
export function getNetworkType (): NetworkType {
  const ua = navigator.userAgent
  const matchArr = ua.match(/nettype\/\w+/i)
  const typeStr = matchArr ? matchArr[0].toLowerCase().replace('nettype/', '') : ''
  const networkType: NetworkType = {
    type: 0,
    generation: 0
  }
  switch (typeStr) {
    case 'wifi':
      networkType.type = 4
      break
    case '5g':
      networkType.generation = 5
      break
    case '4g':
      networkType.generation = 4
      break
    case '3g':
    case '3gnet':
      networkType.generation = 3
      break
    case '2g':
      networkType.generation = 2
      break
  }
  return networkType
}
