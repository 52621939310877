
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { APIResponse } from '@/interface';
@Component({
  name: 'DoctorInfo',
  components: {},
})
export default class DoctorInfo extends Vue {
  @Prop()
  private list!: any;
  protected errorImg: string = require('@/assets/clinic/defaultAvatar.png');
  // @Watch('list', { deep: true })
  // private listChange (v: any) {
  //   this.list = v
  //   console.log(this.list)
  // }
  private appointment(item: any) {
    if (!this.$store.getters.getUserInfo || !this.$store.getters.getUserInfo.name) {
      this.$store.dispatch('setLoginVisible', true);
      this.$store.dispatch('setDoctorId', item.id);
    } else {
      this.$router.push('/sjmyClinic/doctor/detail?id=' + item.id);
    }
  }
}
