import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
 {
  path: '/',
  component: Home,
  children: [
   // 主页
   {
    path: '/',
    component: () => import('@/views/Dash.vue'),
    name: 'HomeNew',
    meta: {
     isFooterFixed: false,
     footerMarginBottom: false
    }
   },
   // 关于我们
   {
    path: '/about',
    component: () => import('@/views/About.vue'),
    name: 'About',
    meta: {
     isFooterFixed: false,
     footerMarginBottom: false
    }
   },
   // 合作伙伴
   {
    path: '/partner',
    component: () => import('@/views/Partner.vue'),
    name: 'Partner',
    meta: {
     isFooterFixed: false,
     footerMarginBottom: false
    }
   },
   {
    path: '/dash',
    component: () => import('@/views/Dash.vue'),
    name: 'Dash',
    meta: {
     isFooterFixed: true,
     footerMarginBottom: false
    }
   },
   {
    path: '/medicineList',
    component: () => import('@/views/MedicineList.vue'),
    name: 'MedicineList',
    meta: {
     isFooterFixed: false,
     footerMarginBottom: false
    }
   },
   {
    path: '/doctorList',
    component: () => import('@/views/DoctorList.vue'),
    name: 'DoctorList',
    meta: {
     isFooterFixed: false,
     footerMarginBottom: false
    }
   },
   {
    path: '/medicine',
    component: () => import('@/views/Medicine.vue'),
    name: 'Medicine',
    meta: {
     isFooterFixed: false,
     footerMarginBottom: false
    }
   }
  ]

 },

 {
  path: '*',
  redirect: '/'
 }
]

const router = new VueRouter({
 mode: 'history',
 base: '/',
 routes
})
router.beforeEach(async (to: any, from: any, next: any) => {
 store.commit('footerFixed', to.meta.isFooterFixed)
 store.commit('footerMarginBottom', to.meta.footerMarginBottom)
 // 友盟web统计；2019.12.11
 sessionStorage.setItem('preRoutePath', from.path)
 // if (!to.meta.needLogin) {
 //   next()
 // } else {
 //   store.getters.getUserInfo()
 //   next()
 // }
 next()
})
export default router
